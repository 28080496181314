.acccontainer{
  height: 100%;
  display: flex;
  max-height: max-content;
  margin-bottom: 20%;

}
.accleftbar{
  max-width: 350px;
  min-width: 250px;
  height: 100vh;
  z-index: 10px;
  background-color: #fff;
  box-shadow: 6px 3px 5px 0px #CFD1D5;
-webkit-box-shadow: 6px 3px 5px 0px #CFD1D5;
-moz-box-shadow: 6px 3px 5px 0px #CFD1D5;
}
.accleftbar h1{
  margin: 10px;
  font-size: 40px;
}
.accleftbar .settingTabs{
  width: 100%;
}
.accleftbar .settingTabs ul{
  list-style: none ;
  line-height: 60px;
}
.accleftbar .settingTabs ul li{
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
}
.accleftbar .settingTabs ul li:hover{
  background-color: #f1f3fa;
  padding-left: 10px;
  border-top-left-radius: 10px;  
  border-bottom-left-radius: 10px;  
  color: #645959;
}
.accrigthbar{
  width: 1280px;
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 0px 10px;
  padding-top: 20px;
}
.accountDetgen{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}
.accAccountD{
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid black;
  max-width: 1050px;
  min-width: 650px;
  height: max-content;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.headerdetails p{
  font-weight: 500;
}
.headerdetails p,.valuedetails p{
  margin: 5px;
}
.acch1{
  font-family:'Poppins', sans-serif !important;
  font-weight: 700;
}
.accProfile{
  width: 400px;
  height: 400px;
  position: relative;
}

.accProfile img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #252525;
}
.accProfile label{
  position: absolute;
  top: 75%;
  right: 20px;
  border: none;
  border-radius: 50%;
}
.accPassword{
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  overflow-y: scroll;
  margin-bottom: 70px;
}
.accNotifify{
  background-color: #fff;
  border: 2px solid black;
  max-width: 1000px;
  min-width: 800px;
  height:100%;
  overflow-y: scroll;
  border-radius: 10px 0px 0px 10px;
  padding: 15px;
}
.sortbtnNotify button{
 margin-left: 10px;
 width: 80px;
 height: 40px;
 padding: 2px;
 font-size: 18px;
}
.btnnotifactive{
  border: none;
  border-radius: 18px;
  background-color: #AAC9FF;
  color:#1877F2 ;
}
.inactive{
  border:none !important;
  background-color: transparent;
  font-weight: 500;
}
.accNotifify .listofNotif .notif{
  display: flex;
  padding: 10px;
  height: 120px;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;
}
.accNotifify .listofNotif .notif img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-right: 15px;
}
.unreadnotif{
  color: rgb(36, 170, 255);
  font-weight: 700;
}
.rightnotif{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
}
.accNotifify .listofNotif .notif .truncated-text1{
  overflow: hidden;
  white-space: normal;
  height: 100px;
  text-overflow: ellipsis;
}
.circle{
  background-color: #1877F2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
}
.userlog{
  background-color: #fff;
  border: 2px solid black;
  width: 800px;
  overflow: auto;
  height: 100%;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 80px;
  position: relative;
}
.userlog ul{
  list-style: none ;
}
.userlog ul li{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}