@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.registration{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../userhome/assets/municipal.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.registrationcon{
    height: 50%;
    border-radius: 8px;
    padding: 0px 50px 0px 50px;
}

.registrationfrm{
    width: 100%;
    height: 100%;
    font-family: 'Inter', sans-serif;
    border-radius: 8px;

}
.registrationfrm form{
    display: flex;
    width: 100%;
    height: 100%;
}
.registrationfrm h1{
    margin: 0;
    margin-top: 20px;
    text-align: center;
}
.regfrmcontainer{
    width: max-content;
    min-width: max-content;
    height: 100%;
    max-height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: white;
    flex-direction: column;
}
.regfrmcontainer h2{
    margin-bottom: 10px;
    margin-top: 30px;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 32, 203, 1);
}
.mydo{
    width: 50%;
    height: 100%;
    object-fit: cover;
    background-color: aquamarine;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.emailotpreg{
    padding: 10px;
    width: 700px;
    min-width: max-content;
    height: 500px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
}
.regbtnregnex{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.otpfreg{
    padding: 10px;
    width: 700px;
    min-width: max-content;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 0px;
}
.otp-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .otp-input:focus {
    border-color: #007bff;
  }
.createacccon{
    padding: 10px;
    width: 700px;
    min-width: max-content;
    height: 500px;
    max-height: max-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
}
.Fieldlog{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.inputeval{
    width: 100%;
    padding: 10px;
}
.fdet{
    display: flex;
    width: 100%;
    padding: 10px;
}
.otpfreg h2{
    font-size: 36px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.labelsinp{
    font-weight: bold;
}
.emailotpreg h2,.createacccon h2{
    font-size: 36px;
    color: darkgreen;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.emailotpreg p{
    margin: 0;
    text-align: center;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.bacreotp{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
}
.bacreotp div{
    width: 30%;
}
.perrors{
    width: 100%;
}
.inputss{
    width: 100%;
}
