@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.newsec{
  width: 100%;
  height: 100%;
  padding-bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

}
.newsheader{
  font-family: 'Inter', sans-serif;
}
.ncard {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  padding-top: 20px;
  }
 .latestnews{
  width: 49%;
  height: 100%;
  padding: 10px;
 }
 .news{
  width: 50%;
  height: 100vh;
  overflow: auto;
 }
 .imgnews{
   width: 100%;
   height: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 20px;
   margin-top: 10px;
 }
 .imgnews img{
  width: 100%;
  height: 90%;
 }
 .newscon{
    width: 95%;
    height: 200px;
    padding: 10px;
    margin: 5px;
 }