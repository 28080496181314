@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.hcard {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  border: 2px solid rgb(254, 249, 249);
  height: 70vh;
  width: 100%;
  }
  .grad {
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0));
  }
  .text-box {
    padding: 2%;
    text-align: center;
    margin: 0;
    color: rgb(1, 1, 1);
    font-style: italic;
    font-size: 2.0em;
    letter-spacing: 5px;
    font-family: 'Inter', sans-serif;
  }

  .text-title {
    padding: 2%;
    text-align: center;
    margin: 0;
    color: rgb(1, 1, 1);
    font-weight: bold;
    font-style: italic;
    font-size: 2.4em;
    letter-spacing: 5px;
    font-family: 'Inter', sans-serif;
  }