@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.anncard {
    font-family: 'Inter', sans-serif;
    border-radius: 6px;
    height: 100vh;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    padding-bottom: 70px;
  }
  .announcements{
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100%;
    margin: 5px;
    border-radius: 15px;
    padding: 10px;
  }
  .annhead{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .annhead p{
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 5px;
    margin-right: 20px;
  }
  .anncard .annhead img{
    width: 70px;
    height: 70px;
  }
.anncontent{
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
  grid-gap: 10px;
}
.announcements .annto{
  white-space: nowrap;
  display: flex;
}
.announcements .annto p{
  font-style: italic;
  font-size: 12px;
  margin-right: 10px;
}
.towhom p{
  font-weight: bold;
  font-size: 12px;

}
.anntitle h3{
  margin: 0;
}
.anncontent{
  margin: 10px 0 10px 0;
}