@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.faqs-container{
    margin-top: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: top;
    justify-content: center;
}
.faqs-card{
    width: 80%;
    height: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;

}
.faqs-header{
    width: 100%;
    height: 100px;
}
.faqs-header img{
    width: 100%;
    height: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

.faqs-accordion{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.FaqsQuestions{
    background-color: white;
    color: black;
}
.FaqsQuestions:hover{
    background-color: rgb(0, 255, 128);
    color: black;
    transition: background-color 0.3s ease;
}