.renewalcontainer{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: top;
    padding-top: 20px;
    border-radius: 5px;
}
.renewalform{
    width: 800px;
    display: grid;
    grid-template-columns: repeat(autofit,minmax(400px,1fr));
    background-color: white;
    height: max-content;
    padding: 10px;
}
.renewalform h1{
    margin: 0px;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 30px;
    font-weight: 600;
}
.searchid{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px, 1fr));
    grid-gap: 10px;
}
.schlabel{
    background-color: blue;
    color: white;
    font-weight: 700;
    border: 1.7px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    border-right: none;
    z-index: 22;
}
.schid{
    padding: 5px;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 10px;
    margin-left: -5px;
}
.schoidbtn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
}