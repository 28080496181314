@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.userscho {
    font-family: 'Inter', sans-serif;
    height: 100%;
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reqheadtitle{
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  width: 100%;
}
.userequirements{
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
  grid-gap: 10px;
  height: 100%;
  margin-bottom: 10px;
}
.reqlistcontainer{
  width: 100%;
  
  background-color: white;
}
.schousercont{
  width: 100%;
  height: 100%;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.schousercont h1{
  margin: 10px;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.userschocont{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userschocont h1{
  margin: 0;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.requirelist{
  width: 100%;
  display: flex;

  max-height: 200px;
}
.userlistreq{
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
.userlistreq label{
  font-size: 20px;
  margin: 10px;
  align-items: flex-start;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.userlistreq input[type="file"],.inputsub input[type='file'] {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: whitesmoke;
  color: #333;
  font-size: 16px;
  width: 70%;
  margin: 10px;
}
.requireprev {
  width: 20%;
  min-height: 100%;
  margin: 10px;
}
.requireprev img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userdocusub{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.docusibmitted{
  display: flex;
  height: 100%;
}
.docusubprev{
  width: 20%;
  min-height: 100px;
  margin: 10px;
}
.docusubprev img{
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.userdocsubstat{
  width: 80%;
  padding: 10px;
  height: 100%;
}
.userdocsubstat p{
  margin: 0;
}
.usersbumtdoc{
  margin: 0;
  padding: 10px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
  grid-gap: 10px;
  height: 100%;
  min-height: 100px;
  margin-bottom: 10px;
}
.btnschoupreq{
  margin: 10px 0px 10px 0;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnschoupreq button:hover{
  color: white;
  letter-spacing: 2px;
  font-weight: 700;
  background: radial-gradient(100% 100% at 100% 0%, #fdff89 0%, rgb(28, 147, 77) 100%);
}
.Nodocupost{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Nodocupost p{
  font-style: italic;
}
.looping{
  width: 100%;
  height: 100%;

}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.tabpanel-container {
  position: relative;
}