@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.contappoint{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;

}
.appointheader{
  text-align: center;
  font-size: 40px;
  color: black;
  font-weight: 800;
  background-color: transparent;
  margin-top: 20px;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.sicard {
  margin: 0;
  padding: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
  grid-gap: 10px;
  height: 100%;
  padding: 10px 10px 50px 10px;
  }
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .calendar {
    position: relative;
    width: 200px;
    height: 200px;
    background-image: url("https://cdn4.iconfinder.com/data/icons/small-n-flat/24/calendar-1024.png");
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: scroll;
    background-size: cover;
  }
  
  .calendar img {
    width: 100%;
    height: auto;
  }
  
  .calendar .text {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
    font-size: 20px;
    color: black;
    font-weight: 800;
    background-color: transparent;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }
  .appointscon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .appointdetails{
    text-align: left;
    width: auto;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }
  .appointlistcon{
    margin: 0;
    padding: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    grid-gap: 10px;
    height: 100%;
    background-color: aqua;
  }