.stepdiv{
    width: 100%;
    height: max-content;
    min-height: 100vh;
    border: none;
    padding-bottom: 30px;
}
.stepdiv h1{
    margin: 0;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    padding-top: 20px;
    font-weight: 700;
    text-align: center;
    color: #0b4980;
}

.stepper{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    color: #0b4980;
    height: max-content;
    padding: 40px 0px 20px 0px;
    background-color: rgb(50, 161, 161);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}