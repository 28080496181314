@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.lschoCat{
    background-image: url('../../userhome/assets/municipal.jpg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: scroll;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    grid-gap: 10px;
    padding: 20px;

}
.schohp{
    text-align: center;
    font-family: 'Inter', sans-serif;
    background-color: yellow;
    margin: 0;
    padding: 20px;
}

.schoCat{
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    height: 300px;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.schoIcon{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: top;
    justify-content: center;
}

.schoDet{
    height: inherit;
    font-family: 'Inter', sans-serif;
    text-align: left;
}
.schoDet .ntitle{
    color: darkgreen;
}
.schoDet .ndate h6{
    font-size: 15px;
}
.btncontainerscho{
    display: flex;
    justify-content: center;
    align-items: center;
}
.linkingscho{
    color: white;
    text-decoration: none;
    position: relative;
}