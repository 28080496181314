@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.newexpage{
    top: 20%;
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
}
.newexpage h1{
    font-family: 'Inter', sans-serif;
    margin: 40px 0px 60px 0px;
}
.lexpage{
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border: 2px solid black;
    background-color: white;
    border-radius: 20px;
}
.lnewpage{
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border: 2px solid black;
    background-color: white;
    border-radius: 20px;
}
.gotolog, .gotoreg{
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 30px;
    margin: 0;
    color: darkgreen;
    text-align: center;
    text-decoration: none;

}