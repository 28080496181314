.donepage{
    margin-left: 10%;
    width: 80%;
    height: 100%;
    line-height: 25px;
    margin-top: -20px;
    padding: 10px 20px 50px 20px;
    background-color: white;
}
.btnlogchoice{
    display: flex;
}