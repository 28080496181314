@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.Econ{
    margin-left: 10%;
    width: 80%;
    height: 100%;
    min-height: 400px;
}
.Econd{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    line-height: 25px;
    margin-top: -20px;
}
.infocontainer{
  padding: 10px;
  border-radius: 0px;
  margin-bottom: 20px;
  line-height: 25px;
  overflow: hidden;
}

.frmbtnec{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 97%;
    margin-top: 30px;
}