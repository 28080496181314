body{
    margin: 0;
    padding: 0;
    background-color: #f1f3fa;  
}
#root{
    margin: 0;
    padding: 0;
}
.head{
    max-width: 100vw;
    min-width: 1200px;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
