@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.footer{
    max-width: 100vw;
    min-width: 1200px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    color: whitesmoke;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.footer .copyr{
    margin-right: 40px;
    margin-left: 20px;
}
.footer .location{
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .location img{
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
.footer .url{
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .url img{
    margin-right: 10px;
    width: 25x;
    height: 25px;
}
.footer .contact{
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .contact img{
    margin-right: 10px;
    width: 25px;
    height: 25px;
}