@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
*{
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.containcont{
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.lcontact{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}
.bmcclinkfb{
    text-transform: none;
}
.contactdetails{
  width: 40%;
  height: 100%;
  padding: 10px;
}
.lcntctcon{
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.cntctde{
    width: 100%;
    height: 40%;
}
.cntcthder h1,.logcontac h1{
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 700;
    font-size:1.5rem ;   
    margin: 20px 0px 10px 0px;
}
.cntcthder p,.logcontac p{
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-style: italic;
}
.logcontac{
    width: 100%;
}

.cntctinf{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.cntctcard{
    width: 30%;
    padding: 10px;
    height: 120px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: white;
    align-items: center;
    justify-content: top;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cntctcard img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.carocon{
    width: 100%;

}
.carousel {
    width: 200px;
    height: 400px;
  }
  .carousel-slide {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel-slide.rs-transition-enter-active,
  .carousel-slide.rs-transition-enter-done {
    opacity: 1;
  }
  
  .carousel-slide.rs-transition-exit,
  .carousel-slide.rs-transition-exit-done {
    opacity: 0;
  }
  .carousel.carousel-slider .control-arrow {
    top: 0;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}
  
  .carousel .control-arrow:hover {
    background: #ccc;
  }
  
  .carousel .control-arrow.prev {
    left: 20px;
  }
  
  .carousel .control-arrow.next {
    margin: 10px;
    right: 20px;
  }
  
  .carousel .control-arrow.disabled {

    opacity: 0.5;
    cursor: not-allowed;
  }
  .hrhead{
    width: 100%;
    height: 50px;
    text-align: center;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    letter-spacing: 10px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .maploc {
    width: 50%;
    padding: 10px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .maploc iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
  @media (max-width:500px) {
    .lcontact{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      margin-bottom: 50px;
  }
  .contactdetails{
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .maploc {
    width: 100%;
    padding: 10px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hrhead{
    width: 100%;
    height: 50px;
    text-align: center;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    letter-spacing: 10px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}