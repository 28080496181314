@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Persona{
    margin-top: -20px;
    margin-left: 10%;
    width: 80%;
    height: 100vh;
    border: none;
}
.Persona h2{
   font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    text-align: left;
    height: 50px;
    margin: 0;
    padding-left: 20px;
    padding-top: 10px;
    border: none;
    background-color: blue;
    color: white;
}
.form{
  background-color: white;
  height: max-content;
  padding-bottom: 20px;
}
.personad{
    width: 100%;
    height: 100%;
    border: none;
}
.parentcontainer{
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
  line-height: 35px;
  padding: 10px;
  
}
.parentcontainer1{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 55px;
  padding-bottom: 20px;
}
.parentcontainer h3{
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 15px;
  color: rgb(11, 73, 128);
}
 .parenteach{
  width: 100%;
  height: max-content;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.parenteach div{
  margin:'5px';
  padding: 3px;
  color: rgb(11, 73, 128) ;
}
.parenteach1{
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  color:rgb(11, 73, 128)  ;
  justify-content: space-between;
  padding-left: 15px;
}
.parenteach1 div{
  width: 100%;
}
.parenteach2{
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #043F97;
}
.parenteach2 h3{
  font-size: 23px;
  font-weight: 700;
  white-space: nowrap;
  margin: 0px;
  color: white;
  padding-left: 10px;
}
.btnfrmn{
    display: flex;
    width: 97%;
    font-size: 20px;
    margin-top: 15px;
    align-items: flex-end;
    justify-content: flex-end;
}
.form-label{
  margin: 0px;
}
.siblinginf{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px 5px 45px;
}
.siblinginf div{
  width: 90%;
}
.siblingcontainer{
  width: 100%;
  height: max-content;
  color: rgb(11, 73, 128) ;
}
.siblingcontainer h3{
  font-size: 23px;
  font-weight: 700;
  white-space: nowrap;
  margin: 0px;
  color: white;
  background-color: #043F97;
  padding-left: 10px;
  padding: 20px;
}
.addbtnsib{
  width: 100%;
  display: block;
  position: relative;
  height: 50px;
  margin-top: 15px;
}
