input[type="password"]::-webkit-password-reveal-button {
  display: none;
}

/* For Internet Explorer */
input[type="password"]::-ms-reveal {
  display: none;
}

/* For some Edge versions */
input[type="password"]::-ms-clear {
  display: none;
}
  