@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.labout{
    width: 90%;
    height: 100%;
    background-color: white;
    padding-bottom: 50px;
}
.latitle{
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    height: 20%;

}
.latitle img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.mvcontainer{
    display: flex;
    width: 100%;
    height: max-content;

}
.wrapper{
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: space-around;
}
.amvp{
    font-family: 'Inter', sans-serif;
    width: 30%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
}
.aabstract{
    font-family: 'Inter', sans-serif;
    letter-spacing: 10px;
    display: block;
    font-size: 24px;
    font-weight: 1000;
    padding: 15px;
    text-align: center;
    background-color: green;
    color: rgb(255, 217, 0);
    width: 100%;
    height: 40%;
}
.bmccofficials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.abmccmem{
    width: 90%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    grid-gap: 10px;
    height: 100%;
    margin-bottom: 10px;
}

/* styles.css */
.grow-enter {
    transform: scale(0);
    opacity: 0;
  }
  
  .grow-enter-active {
    transform: scale(1);
    opacity: 1;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
  }
  
  .grow-exit {
    transform: scale(1);
    opacity: 1;
  }
  
  .grow-exit-active {
    transform: scale(0);
    opacity: 0;
    transition: transform 1000ms, opacity 1000ms;
  }
  .cardprofile{
    width: max-content;
    box-shadow: 0px 1px 5px 7px rgba(122, 122, 122, 0.25) inset;
    -webkit-box-shadow: 0px 1px 5px 7px rgba(122, 122, 122, 0.25) inset;
    -moz-box-shadow: 0px 1px 5px 7px rgba(122, 122, 122, 0.25) inset;
    padding: 5px;
    border-radius: 2px;
    transition: all 0.3s ease;
}
.cardprofile:hover,.circular:hover {
    box-shadow: 0px 1px 10px 7px rgba(45, 2, 235, 0.801) inset;
    transform: scale(2.05);
  }
.backgroundimg{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 80px;
    background-size: cover;
    background-position: center;
    margin-bottom: 25px;
    border-bottom: 2px solid black;
}
.circular{
    display: flex;
    position: fixed;
    top: 20px;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 5px;
    border: 2px solid transparent; 
    border-radius: 50%; 
    transition: all 0.3s ease; 
}
.circular:hover {
    border-color: #007bff; 
  }
.mydoofficials{
    text-decoration: underline;
    font-size: 35px;
    font-weight: bold;
}
@media (max-width:728px) {
    .labout{
        width: 100%;
        height: 100%;
        background-color: white;
        padding-bottom: 50px;
    }
    .mvcontainer{
        display: flex;
        width: 100%;
        padding: 15px;
        height: max-content;
    }
    .wrapper{
        display: flex;
        width: 100%;
        flex-direction: column;
        height: max-content;
        justify-content: space-around;
        overflow: hidden;
    }
    .amvp{
        font-family: 'Inter', sans-serif;
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        padding: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 25px;
    }
    .abmccmem{
        width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(max-content, 1fr));
        grid-gap: 10px;
        height: 100%;
        margin-bottom: 10px;
    }

}
