@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.Header{
    max-width: 100vw;
    min-width: 1200px;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: space-between;
    height: 75px;
    background-color: rgb(231, 235, 240);
    margin: 0;
    box-shadow: 0 4px 2px -2px rgb(68, 63, 63);
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.BMCC{
    display: flex;
}

.BMCC .bmcc{
    margin: 5px 20px 0 15px;
}

.BMCC .title{
    letter-spacing: 15px;
    margin: 9px 0 20px 25px;
    font-weight: bold;
    font-size: 30px;
}

.LINK {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

