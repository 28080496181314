@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.FirstepFrm{
    width: 80%;
    margin-left: 10%;
    height: max-content;
    background-color: white;
    color: #0b4980;
}
.ribbon-header {
    width: 80%;
    background-color: white;
    color: #0b4980;
    border: none;
    position: relative;
    margin: 0px 0px 20px 0px;
    margin-left: 10%;
  }
  /* .ribbon-header::before,
  .ribbon-header::after {
    content: '';
    position: absolute;
    bottom: -20.5px;
    width: 0;
    height: 0;
    border: 21px solid transparent;
  } */
  
  /* .ribbon-header::before {
    height: 79px;
    left: -41px;
    border-right-color: blue;
  
  }
  
  .ribbon-header::after {
    height: 79px;
    right: -41px;
    border-left-color: blue;
  
  } */
  .ribbon-header-text {
    position: relative;
    border: none;
    padding: 6px 6px 6px 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.form{
    background-color: white;
    height: max-content;
    padding-bottom: 20px;
}
.h4head{
    font-size: 30px;
    color: white;
    padding: 10px;
}
.containerform{
    margin-top: -20px;
    margin-bottom: 20px;
    line-height: 25px;
}
.frmlabel{
    font-weight: 700;
    color: black;
}
option{
  text-transform: uppercase;
}

