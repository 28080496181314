@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
.navbarh{
    margin: 0px;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.containernavbar{
    width: 100%;
    display: flex;
}
.scho-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.nameprof{
    display: flex;
}
.nameprof .profile{
    margin: 15px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.nameprof img{
    width: 70px;
    height: 70px;
    border-radius: 35px;
}
.nameprof .info{
    padding: 15px;
}
.nameprof .info .details{
    letter-spacing: 2px;
    margin: 0;
    font-size: 18px;
    text-decoration: none;
}
.nameprof .info .details small .stat{
    font-size: 15px;
    margin-left: 10px;
}
.nameprof .notific{
    display: flex;
    cursor: pointer;
}
.link{
    text-decoration: none;
}
.activeBar{
  background-color: aqua;
  padding: 5px 30px 5px 30px;
  border-radius: 5px;
}
.navsec{
    width: 100%;
    left: 0;
    height: 30%;

}
.navsec ul{
    display: flex;
    padding: 10px;
    margin: 0;
    font-size: 15px;
    list-style-type: none;
    line-height: 35px;
    justify-content: space-between;
}
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    color: whitesmoke;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: whitesmoke;
  }
  
  .dropdown-content a:hover {background-color: whitesmoke;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: whitesmoke;}

  .notif{
    margin-right: 20px;
    position: relative;
  }
  .nonotif{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  .notifcontainer{
    display: flex;
    padding: 10px;
    height: 120px;
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .notifcontainer img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid grey;
    margin-right: 15px;
  }