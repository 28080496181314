@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
*{
    box-sizing: border-box;
}

.ascholarship{
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: max-content;
    background-color: white;
    padding: 20px;
}
.aschohead{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.aschohead h1{
    font-style: none;
    border: none;
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;
}
.aschohead h2{
    font-size: 18px;
    color: green;
    text-align: center;
}
.requireascho{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}
.ascho-card{
    width: 40%;
    padding: 15px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(16, 172, 78);
    border-radius: 5px;
    background-color: white;
}
.hascho{
    font-size: 15px;
    color: greenyellow;
    white-space: nowrap;
}
.hascho h1{
    font-size: 20px;
    margin-bottom: 15px;
    color: green;
}
.ascholi{
    width: 100%;
    height: 100%;
}
.ascholi p{
    margin: 0;
}
.aruqua{
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aruqua h1{
    font-size: 17px;
    color: black;
    white-space: nowrap;
    padding: 15px;
    border: none;
    border-radius: 10px;
}
.alinkapp{
    color: black;
    text-decoration: none;
    border: 2px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: ease;
}
.alinkapp:hover{
    background-color:#4CAF50 ;
    color: white;
    padding: 7px;
}
@media (max-width:630px) {
    .ascholarship{
        font-family: 'Inter', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: max-content;
        background-color: white;
    }
    .requireascho{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .ascho-card{
        width: 100%;
        padding: 15px;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(16, 172, 78);
        border-radius: 5px;
        margin-bottom: 15px;
        background-color: white;
    }
    .hascho{
        font-size: 15px;
        color: greenyellow;
        white-space: nowrap;
    }
    .hascho h1{
        font-size: 15px;
        margin-bottom: 15px;
    }
}
@media (max-width:900px) {
    .hascho{
        font-size: 15px;
        color: greenyellow;
        white-space: nowrap;
    }
    .hascho h1{
        font-size: 15px;
        margin-bottom: 15px;
    }
}