
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1000;
  }
  
  .scroll-to-top-button:hover {
    background-color: #0056b3;
  }
  