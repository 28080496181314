@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
.loginpage{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/municipal.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.loginheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;

}

.loginpage .lgbm{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
}
.lgform{
    width: 500px;
    min-width: max-content;
    height: max-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
}
.logbmcc{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 10px 0px;
}
.password-input-container,.email-input-container{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
}
.labellogin{
    font-weight: 500;
}
.inputpass,.inputemail,.inputss{
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;
    margin-bottom: 5px;
    outline: none;
    transition: border-color 0.3s; 
}
.inputpass:focus,.inputemail:focus,.inputss:focus {
    border-color: #007bff; 
  }
.lgcon{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 25px 10px;
}
.lgcon .logbmcc img,.findtocon .logbmcc img,.otpverifycon .logbmcc img,.restfrmpass .logbmcc img{
    width: 100px;
    height: 90px;
    border-radius: 50%;
    z-index: 10000;
    background-color: white;
    margin-top: -60px;
    box-shadow: -2px 10px 5px 0px rgba(0,0,0,0.46);
    -webkit-box-shadow: -2px 10px 5px 0px rgba(0,0,0,0.46);
    -moz-box-shadow: -2px 10px 5px 0px rgba(0,0,0,0.46);
}
.lgcon .logbmcc p,.findtocon .logbmcc p,.otpverifycon .logbmcc p,.restfrmpass .logbmcc p{
    margin-top: 20px;
    font-size: 32px;
    color:rgba(32, 76, 234, 1);
    font-weight: 900;
    line-height: 38.73px;
}
.lgcon img{
    z-index: -100;
}
.lgcon h1{
    margin: 0;
    padding: 20px 0px 10px 0px;
    text-align: center;
}
.signforgot{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.lgcon form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 15px;
}
.lgbtn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.lgcon form button{
    width: 60%;
    padding: 10px;
    border: none;
    font-size: 17px;
    color: white;
}
.loglink{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.lglink{
    text-decoration: none;
    font-size: 15px;
}
.findtocon{
    width: 500px;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.findtocon h2{
    margin: 0;
    padding: 20px 0px 10px 0px;
    text-align: center;
}
.otpfform{
    width: 100%;
    height: 100%;
    padding: 10px 10px 20px 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.otpfform p{
    margin-bottom: 20px;
}
.otpfnsc{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.bacreotp{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otpverifycon{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
}
.otpverifycon h2{
    margin: 0;
    padding: 20px 0px 10px 0px;
    color: white;
    text-align: center;
}
.formotpvalif{
    width: 90%;
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.restfrmpass{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.restfrmpass h1{
    margin: 0;
    padding: 20px 0px 10px 0px;
    color: white;
    text-align: center;
}
.updatepassresfrm{
    width: 80%;
    padding: 10px;
    padding-bottom: 15px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.red {
    color: black; 
    transition: color 0.5s; 
  }
  
  .animate-red {
    color: red; 
  }
  

  @keyframes removeRed {
    0%, 100% {
        color: red;
        transform: translateX(0); 
      }
      10%, 30%, 50%, 70%, 90% {
        color: red;
        transform: translateX(-15px); 
      }
      20%, 40%, 60%, 80% {
        color: red;
        transform: translateX(15px); 
      }
  }
  body.animate-red p.red {
    color: red;
    animation: removeRed 2s;
  }