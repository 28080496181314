.page{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: max-content;
}
.hrdivider{
    width: 90%;
    height: 50px;
    background-color: yellow;
}
.hrhead{
    width: 90%;
}
.pagecontent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width:700px) {
    .hrhead{
        width: 100%;
    }
}