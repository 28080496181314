.trvdaycon{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tcard {
    font-family: 'Inter', sans-serif;
      border-radius: 6px;
      width: 80%;
      height: 80vh;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
      grid-gap: 10px;
      margin-bottom: 20px;
      padding: 10px;
      padding-bottom: 0px;
    }
    .trivhead{
      text-align: center;
      align-items: center;
    }
    .trivcard{
      width: 100%;
      height: 100%;
    }
    .triv{
      display: flex;
      padding: 10px;
    }
    .tri-img{
      display: flex;
      width: 70%;
      height: 400px;
      margin-right: 10px;
    }
    .tri-img img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .destriv{
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: top;
    }
    .tri-title h1{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-weight: bold;
      color: green;
    }